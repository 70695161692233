import React from "react";
import { Link } from "gatsby";

let data = require("../../data/neighborhoods.json");

class Neighborhood extends React.Component {
  render() {
    return (
      <section id="neighborhoods" className="component bg-dirty-white">
        <div className="container">
          <div className="intro text-center mb-5">
            <h5 className="subtitle text-uppercase text-danger">
              Search by Area
            </h5>
            <h2>Neighborhoods</h2>
            <p>
              We live here, we work here. Every neighborhood has its own
              personality, history and is within your reach.
            </p>
          </div>
          <div className="row flex-wrap justify-content-center m-0">
            {data.map((location) => (
              <div className="col-sm-6 col-lg-4 p-3" key={location.id}>
                <div
                  className="area"
                  style={{
                    backgroundImage: location.image,
                  }}
                >
                  <div className="area-content">
                    <h4 className="text-white">
                      {location.url ? (
                        <Link
                          to={location.url}
                          className="text-white font-weight-normal"
                          title={location.title}
                        >
                          {location.title}
                        </Link>
                      ) : (
                        <button className="p-0">{location.title}</button>
                      )}
                      <img
                        className="camera-icon ml-2"
                        src="/img/record.svg"
                        alt="video camera"
                      />
                    </h4>
                    <p>{location.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
}

export default Neighborhood;

import React from "react";
import { Link } from "gatsby";

const Military = () => (
  <section id="military" className="component">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 ml-lg-auto">
          <div
            className="content bg-white p-5 rounded-lg"
            style={{
              boxShadow:
                "0 0px 0px 0px rgb(0 0 0 / 26%), 0 4px 25px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)",
            }}
          >
            <div className="intro mb-4">
              <h2>
                Let Our Military Relocation Professional Help You In Your Home
                Buying Process
              </h2>
            </div>
            <p>
              Finding the right home can be a difficult process but no matter
              what you are looking for as an experienced Colorado Springs, CO
              real estate agent I can provide you with the resources you need to
              make the home buying process simpler, from specific Colorado
              Springs, CO neighborhood information, to tools to estimate your
              mortgage payments, and buyer resources and articles that will help
              you through the home buying process.
            </p>
            <div className="d-flex justify-content-start mt-5">
              <Link to="/military" className="alink hvr-shadow">
                Read More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Military;

import React from "react";
import { Link } from "gatsby";

class Articles extends React.Component {
  render() {
    const posts = this.props.posts;

    function dateToYMD(date) {
      let d = date.getDate();
      let m = date.getMonth() + 1; //Month from 0 to 11
      let y = date.getFullYear();
      return (
        "" + y + "-" + (m <= 9 ? "0" + m : m) + "-" + (d <= 9 ? "0" + d : d)
      );
    }
    return (
      <section id="articles" className="component bg-dirty-white">
        <div className="container">
          <div className="intro mb-5 text-center">
            <h5 className="subtitle text-uppercase text-danger">resources</h5>
            <h2>Recent Articles</h2>
          </div>
          <div className="row">
            {posts.map((post) => (
              <div className="col-md-6 col-lg-4 mb-4" key={post.id}>
                <div className="card">
                  <div className="card-body articles text-center d-flex flex-column h-100">
                    <div
                      style={{
                        height: 0,
                        paddingBottom: "75%",
                        backgroundSize: "cover",
                        backgroundImage: `url(${post.mainImage.asset.url})`,
                      }}
                    />
                    <div className="py-3 px-2">
                      <h3 className="card-title font-weight-normal mb-4">
                        <Link to={`news/${post.slug.current}`}>
                          {post.title}
                        </Link>
                      </h3>
                      <p className="card-text px-4">
                        {post.body.replace(/[*#]/g, "").substring(0, 200)}...
                      </p>
                    </div>

                    <div className="d-flex justify-content-between align-items-center card-footer px-0 bg-white mt-auto">
                      <div className="card-author px-4">
                        <img
                          className="rounded-circle mr-2"
                          src={post.author.image.asset.url}
                          alt="author"
                          style={{ width: "35px", height: "35px" }}
                        />
                        <span>{post.author.name}</span>
                      </div>
                      <div className="card-created px-4">
                        <img
                          alt="clock"
                          src="/img/clock.svg"
                          width="18"
                          className="mr-1 mb-0"
                        />
                        <span className="text-muted">
                          {dateToYMD(new Date(post._createdAt))}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-center mt-5">
            <Link to="/news" className="alink hvr-shadow">
              View all news
            </Link>
          </div>
        </div>
      </section>
    );
  }
}

export default Articles;

import React from 'react'
import { Link } from 'gatsby'

var slugify = require('slugify')

const FeaturedProperties = ({ listings }) => {
  const availableIsFeatured = listings.filter(
    item => item.StandardStatus !== 'Sold' && item.StandardStatus !== 'sold'
  )
  console.log('availableIsFeatured', availableIsFeatured)
  return (
    <section id="featured-listing" className="component bg-white">
      <div className="container">
        <div className="intro text-center mb-5">
          <h2>Search Properties</h2>
        </div>
        <div className="row align-items-stretch justify-content-center">
          {availableIsFeatured.slice(0, 3).map(listing => (
            <div className="col-md-6 col-lg-4 my-4 mb-lg-0" key={listing.id}>
              <div className="card-column">
                <div className="card bg-white">
                  <div className="card-img">
                    <div
                      className="bg-card"
                      style={{
                        backgroundImage: `url(${listing.Media[0].asset.url})`,
                        backgroundPosition: 'center left',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                      }}
                    />
                    <Link
                      to={`/${slugify(listing.UnparsedAddress)}`}
                      className="btn-mgnfy"
                    >
                      <i className="fa fa-search" aria-hidden="true" />
                    </Link>
                  </div>
                  <div className="card-body">
                    <Link
                      to={`/${slugify(listing.UnparsedAddress)}`}
                      className="btn-mgnfy"
                    >
                      <h4 className="card-title">{listing.UnparsedAddress}</h4>
                    </Link>
                    <ul className="list-unstyled d-flex justify-content-start align-items-center flex-wrap list-features mt-3">
                      <li>
                        {/* <img
                        alt="bed"
                        src="/img/003-bed.svg"
                        width="22"
                        className="mr-1"
                      /> */}
                        {listing.BedroomsTotal}&nbsp;bedrooms,
                      </li>
                      <li>
                        {/* <img
                        alt="shower"
                        src="/img/001-shower.svg"
                        width="22"
                        className="mr-1"
                      /> */}
                        {listing.BathroomsTotalInteger}&nbsp;baths,
                      </li>
                      <li>
                        {/* <img
                        alt="area"
                        src="/img/004-real-estate.svg"
                        width="22"
                        className="mr-1"
                      /> */}
                        {listing.LotSizeSquareFeet}&nbsp;sqft
                      </li>
                    </ul>
                    <h6 className="card-subtitle-home my-2">{`$${(listing.ListPrice &&
                      listing.ListPrice.toString().replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ','
                      )) ||
                      'no listed price'}`}</h6>
                    {listing.VirtualTour ? (
                      <p className="mb-0 mt-3">
                        <a
                          rel="noopener noreferrer"
                          title="Virtual Tour"
                          target="_blank"
                          href={listing.VirtualTour}
                        >
                          <i className="fa fa-camera mr-1" />
                          Virtual Tour
                        </a>
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-center mt-5">
          <Link
            to="/property-listings"
            className="alink hvr-shadow primary-button btn-before"
          >
            more listings
          </Link>
        </div>
      </div>
    </section>
  )
}

export default FeaturedProperties

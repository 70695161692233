import React from 'react'
import benefits from '../../images/benefits-img.jpg'

const BenefitsWorking = () => (
  <section
    className="benefits-section"
    // style={{
    //   backgroundImage: `url(${physicianBG})`,
    //   backgroundColor: '#012d6f',
    //   backgroundPosition: 'center',
    //   backgroundSize: 'cover',
    //   backgroundRepeat: 'no-repeat',
    // }}
  >
    <div className="container px-5 mx-auto">
      <div className="row align-items-center box-shadow">
        <div className="col-12 col-lg-6 px-0">
          <div className="left-info primary-bg-color">
            <h2 className="text-white f-poppins mb-3">
              Benefits of Working with Brian
            </h2>
            <ul className="arrow-list">
              <li>Smart strategies from marketing, negotiating, and closing</li>
              <li>Creative ways to stage your home</li>
              <li>Practical advice to help avoid some costly mistake</li>
              <li>
                Tips to find trusted lenders, the best medical care, handyman,
                cleaning services, and more
              </li>
            </ul>
          </div>
        </div>
        <div className="col-12 col-lg-6 h-100 px-0">
          <div className="bg-image">
            <div
              className="img"
              style={{
                backgroundImage: `url(${benefits})`,
                backgroundColor: '#012d6f',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default BenefitsWorking

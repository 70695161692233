import React from 'react'
import featuredBG from '../../images/featured-listing-bg.jpg'
import { Link } from 'gatsby'

const FeaturedListings = () => (
  <section
    className="featuredListing-section"
    style={{
      backgroundImage: `url(${featuredBG})`,
      backgroundColor: '#012d6f',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    }}
  >
    <div className="container px-4 mx-auto">
      <div className="row">
        <div className="col-12 col-lg-9 mx-auto text-center">
          <div className="featured-content">
            <div className="featured-body bg-white">
              <h2 className="f-poppins">Featured Listings</h2>
              <p className="mt-4 mb-5">
                Is this your first time to buy a home in Colorado Springs? Use
                this financing guide from a Colorado Springs real estate agent
                to help you with the process.
              </p>
              <div className="d-flex flex-wrap flex-lg-nowrap justify-content-center">
                <Link
                  to="/#featured-listing"
                  className="alink hvr-shadow text-center primary-button btn-before border-none mr-md-3 mb-4 mb-md-0"
                >
                  Search Properties
                </Link>
                <Link
                  to="/property-listings/"
                  className="alink hvr-shadow text-center secondary-button btn-before"
                  role="button"
                >
                  Assess Your Property
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default FeaturedListings

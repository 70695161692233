import React from 'react'
import { Link } from 'gatsby'
import contactBG from '../../images/contact-brian-bg.jpg'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const Neighborhood = () => (
  <section
    id="home-valuation"
    className="component"
    style={{
      backgroundImage: `url(${contactBG})`,
      backgroundColor: '#fff',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    }}
  >
    <div className="container">
      <div className="row align-items-center">
        <div className="col-md-6 mb-4 mb-md-0">
          <div className="text-center">
            <LazyLoadImage
              effect="blur"
              alt="couples"
              src="/img/brian-family.jpg"
              width="100%"
            />
          </div>
        </div>
        <div className="col-md-6 mx-auto">
          <div className="d-block mx-xl-4">
            <h2 className="text-black font-italic">
              "With me, it's about my family... my family & yours.”
            </h2>
            <div className="mt-5">
              <Link
                to="/contact/"
                className="alink hvr-shadow primary-button btn-before"
              >
                Contact Brian
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)
export default Neighborhood

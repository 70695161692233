import React from 'react'
import physicianBG from '../../images/blue-outline-bg.jpg'
import { Link } from 'gatsby'

const WatchTestimonial = () => (
  <section
    className="predictions-section"
    style={{
      backgroundImage: `url(${physicianBG})`,
      backgroundColor: '#012d6f',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    }}
  >
    <div className="container">
      <div className="row align-items-center">
        <div className="col-12 col-md-6 text-center text-md-left">
          <h4 className="text-white f-poppins">
            Hear What People Say About Brian Boals
          </h4>
        </div>
        <div className="col-12 col-md-6">
          <div className="d-flex justify-content-center justify-content-md-end">
            <Link
              to="/testimonials/"
              className="hvr-shadow mx-md-2 my-1 text-center white-button btn-before align-items-center d-flex"
              role="button"
              target="_blank"
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 40 41"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mr-2"
              >
                <rect
                  y="0.684204"
                  width="40"
                  height="40"
                  rx="20"
                  fill="#EB1B26"
                />
                <path
                  d="M27.376 21.1002L16.777 28.1662C16.7017 28.2163 16.6142 28.245 16.5239 28.2493C16.4336 28.2536 16.3437 28.2334 16.264 28.1907C16.1843 28.148 16.1176 28.0845 16.0711 28.0069C16.0246 27.9293 16 27.8406 16 27.7501V13.6182C16 13.5277 16.0246 13.439 16.0711 13.3614C16.1176 13.2838 16.1843 13.2203 16.264 13.1776C16.3437 13.1349 16.4336 13.1147 16.5239 13.119C16.6142 13.1233 16.7017 13.152 16.777 13.2022L27.376 20.2681C27.4445 20.3138 27.5006 20.3757 27.5395 20.4482C27.5783 20.5208 27.5986 20.6018 27.5986 20.6842C27.5986 20.7665 27.5783 20.8475 27.5395 20.9201C27.5006 20.9926 27.4445 21.0545 27.376 21.1002Z"
                  fill="white"
                />
              </svg>
              Watch Testimonial
            </Link>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default WatchTestimonial

import React from 'react'
import physicianBG from '../../images/blue-bg2.jpg'

const FeaturedSearch = () => (
  <section
    id="featured-search"
    className="component"
    style={{
      backgroundImage: `url(${physicianBG})`,
      backgroundColor: '#012d6f',
      backgroundPosition: 'bottom',
      backgroundSize: 'cover',
    }}
  >
    <div className="container">
      <div className="intro mb-5 text-center">
        <h2 className="text-white">We Bring the Search to You.</h2>
        <p className="text-white-body">
          We service Colorado Springs and all surrounding areas. We go the extra
          mile to be as close to your fingertips as possible.
        </p>
      </div>
      <div className="search-listing d-flex flex-wrap justify-content-center">
        <div className="search-list mb-4 mb-md-0">
          <div className="text-center">
            <div
              className="search-list-img grayscale-hover"
              style={{
                backgroundImage:
                  'url(https://hcimages.static-homes.com/AccountData/150000000/singlefamily2.jpg)',
              }}
            />
            <h5 className="text-default mt-3 text-white">Single Family</h5>
          </div>
        </div>
        <div className="search-list mb-4 mb-md-0">
          <div className="text-center">
            <div
              className="search-list-img grayscale-hover"
              style={{
                backgroundImage:
                  'url(https://hcimages.static-homes.com/AccountData/150000000/luxuryhome2.jpg)',
              }}
            />
            <h5 className="text-default mt-3 text-white">Luxury Home</h5>
          </div>
        </div>
        <div className="search-list mb-4 mb-md-0">
          <div className="text-center">
            <div
              className="search-list-img grayscale-hover"
              style={{
                backgroundImage:
                  'url(https://hcimages.static-homes.com/AccountData/150000000/condominium2.jpg)',
              }}
            />
            <h5 className="text-default mt-3 text-white">
              Investment Properties
            </h5>
          </div>
        </div>
        <div className="search-list mb-4 mb-md-0">
          <div className="text-center">
            <div
              className="search-list-img grayscale-hover"
              style={{
                backgroundImage:
                  'url(https://hcimages.static-homes.com/AccountData/150000000/residentiallot2.jpg)',
              }}
            />
            <h5 className="text-default mt-3 text-white">Lots</h5>
          </div>
        </div>
        <div className="search-list mb-4 mb-md-0">
          <div className="text-center">
            <div
              className="search-list-img grayscale-hover"
              style={{
                backgroundImage:
                  'url(https://hcimages.static-homes.com/AccountData/150000000/virtualtours2.jpg)',
              }}
            />
            <h5 className="text-default mt-3 text-white">Townhomes</h5>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default FeaturedSearch

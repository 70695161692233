import React from 'react'
import { Link } from 'gatsby'
import Swiper from 'swiper/dist/js/swiper.esm.bundle'
import 'swiper/dist/css/swiper.min.css'

class Testimonials extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      readmore: false,
      shortdesc: '...',
    }
    this.onReadMore = this.onReadMore.bind(this)
  }
  onReadMore() {
    this.setState({ readmore: !this.state.readmore })
  }

  componentDidMount() {
    if (window) {
      new Swiper('.swiper-container', {
        autoHeight: false,
        spaceBetween: 0,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      })
    }
  }
  render() {
    // console.log(this.state.shortdesc, this.state.readmore)
    return (
      <section id="testimonials" className="component bg-white">
        <div className="container testimonial-container">
          <div className="intro text-center mb-5">
            <h5 className="subtitle text-uppercase text-dirty-white">
              Testimonials
            </h5>
            <h2 className="text-white">What Clients Say About Us</h2>
          </div>
          <div className="testimonials text-white text-center">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <div className="swiper-container text-center">
                  <div className="swiper-wrapper">
                    {this.props.testimonials.map(testimonial => (
                      <div className="swiper-slide" key={testimonial.id}>
                        <div className="testimonial">
                          <div className="testimonial-body">
                            <p className="font-weight-normal text-white">
                              {this.state.readmore
                                ? testimonial.description
                                : testimonial.description.slice(0, 356)}
                              <span>
                                {!this.state.readmore
                                  ? this.state.shortdesc
                                  : null}
                              </span>
                            </p>
                            <span
                              style={{
                                cursor: `pointer`,
                                color: `#eb1c26`,
                                textDecoration: `underline`,
                              }}
                              onClick={this.onReadMore}
                            >
                              {this.state.readmore ? 'Show Less' : 'Read More'}
                            </span>
                          </div>
                          <div className="testimonial-author mt-4">
                            <div
                              className={`mb-4 rating-${testimonial.rating}`}
                            >
                              <i className="fa fa-star" aria-hidden="true" />
                              <i className="fa fa-star" aria-hidden="true" />
                              <i className="fa fa-star" aria-hidden="true" />
                              <i className="fa fa-star" aria-hidden="true" />
                              <i className="fa fa-star" aria-hidden="true" />
                            </div>
                            <h5 className="text-uppercase text-white">
                              {testimonial.name}
                            </h5>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="swiper-button-prev" />
                  <div className="swiper-button-next" />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-3">
            <Link to="/testimonials" className="alink-white hvr-shadow">
              See more
            </Link>
          </div>
        </div>
      </section>
    )
  }
}

export default Testimonials

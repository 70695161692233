import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import './cbslideshow.css'
import { Link } from 'gatsby'
//import { Modal, ModalHeader, ModalBody } from 'reactstrap'
//import Iframe from 'react-iframe'
//import window from 'global'
// import AOS from 'aos'
// import 'aos/dist/aos.css'
// import Swiper from 'swiper/dist/js/swiper.esm.bundle'
import 'swiper/dist/css/swiper.min.css'
import Swiper from 'react-id-swiper'
import { Autoplay } from 'swiper'

import im1 from '../../images/heroslide-1.png'
import im2 from '../../images/heroslider-2.jpeg'
import im3 from '../../images/heroslide-3.jpeg'
import im4 from '../../images/heroslide-4.jpeg'
import im5 from '../../images/heroslide-5.jpeg'
import im6 from '../../images/heroslide-6.jpeg'
import im7 from '../../images/heroslide-7.jpeg'
import im8 from '../../images/heroslide-8.jpeg'
import im9 from '../../images/heroslide-9.jpeg'
import im10 from '../../images/heroslide-10.jpeg'
import brianvertical from '../../images/brian-boals-vertical.png'
// import mob1 from '../../images/heromobile-1.png'
// import mob2 from '../../images/heromobile-2.png'
// import mob3 from '../../images/heromobile-3.png'
// import mob4 from '../../images/heromobile-4.png'
// import mob5 from '../../images/heromobile-5.png'
// import mob6 from '../../images/heromobile-6.png'
// import mob7 from '../../images/heromobile-7.png'
// import mob8 from '../../images/heromobile-8.png'
// import mob9 from '../../images/heromobile-9.png'
// import mob10 from '../../images/heromobile-10.png'
import slide1 from '../../images/slideA.png'

// let banner = require('../../data/herobanner.json')
// AOS.init()

const bgImages = [
  {
    bgLink: im1,
    slideHeader: '',
  },
  {
    bgLink: im2,
  },
]

const Hero = () => {
  const params = {
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    // navigation: {
    //   nextEl: '.swiper-button-next',
    //   prevEl: '.swiper-button-prev',
    // },
    // spaceBetween: 30,
  }

  return (
    <div className="hero-section relative">
      <Swiper
        {...params}
        modules={[Autoplay]}
        autoplay={{ delay: 7000 }}
        effect="fade"
        slidesPerView={1}
        className="relative"
      >
        {/* {bgImages.map(item => (
          <div
            style={{
              background: `url(${item?.bgLink})`,
              backgroundSize: 'cover',
            }}
          >
            <div className="">
              <div className="box-con content bg-white p-5 rounded-lg physician-slide">
                <div className="intro mb-4">
                  <h2>Physician Relocation is Our Specialty</h2>
                </div>

                <div className="d-flex justify-content-start mt-5">
                  <Link to="/physicians-medical" className="alink hvr-shadow">
                    Here's Why
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))} */}
        <div className="div-w">
          <img
            className=" d-none hero-slide hero-h mobile-slide"
            src={im3}
            alt=""
          />

          <img className="hero-slide hero-h mobi" src={im3} alt="" />
        </div>
        <div className="div-w">
          <img
            className=" d-none hero-slide hero-h mobile-slide"
            src={im2}
            alt=""
          />
          <img className="hero-slide hero-h mobi" src={im2} alt="" />
        </div>
      </Swiper>
      <div className="box-content">
        <div className="box-con p-5 rounded-lg physician-slide">
          <div className="intro mb-4 text-md-left text-center">
            <h2 className="text-white f-poppins">Find Your Dream Home</h2>
          </div>
          <div className="flex">
            <Link
              to="/#featured-listing"
              className="alink hvr-shadow my-1 text-center primary-button btn-before border-none"
            >
              Search Properties
            </Link>

            <Link
              to="/selling-process/"
              className="alink hvr-shadow mx-md-2 my-1 text-center secondary-button btn-before"
              role="button"
            >
              List Your Home
            </Link>
          </div>
        </div>
      </div>
      <div className="logo-vertical">
        <img className="" src={brianvertical} alt="" />
      </div>
      {/* <div className="container btn-btn">
        <div className="row">
          <div className="col-lg-12 col-xl-11 mx-auto">
            <div className="hero-content text-center">
              <div className="d-flex justify-content-center mt-5">
                <a
                  href="/#featured-listing"
                  className="alink hvr-shadow"
                  role="button"
                >
                  Search Properties
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default Hero

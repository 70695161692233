import React from 'react'
import { Link } from 'gatsby'

const OneStop = () => (
	<section id="one-stop" className="component">
		<div className="container">
			<div className="row">
				<div className="col-lg-6 pr-3 pr-lg-5">
					<div className="content-transparent p-5">
						<div className="intro mb-4">
							<h5 className="subtitle text-uppercase text-danger">
								welcome to brian boals
							</h5>
							<h2 className="text-default">One Stop Real Estate Needs</h2>
						</div>
						<p className="text-default">
							A premier real estate website!. We take pride in providing you the
							best possible service, as well as the latest cutting edge tools,
							to make all your real estate transactions as smooth as possible.
							You won't find another site with more comprehensive, local real
							estate information.
						</p>
						<p className="text-default">
							Whether you are buying or selling, you have come to the right
							place. Bookmark our site, or give us a call. We look forward to
							putting our years of experience to work for you.
						</p>
						<div className="d-flex justify-content-start mt-5">
							<Link to="/contact" className="alink hvr-shadow">
								Contact us
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
)

export default OneStop

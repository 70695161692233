import React from "react";
import { Link } from "gatsby";
import physicianlogo from "../images/physician-logo.png";
import medicinelogo from "../images/medicine-logo.png";
import physicianBG from "../images/blue-bg1.jpg";
import medstudentBG from "../images/blue-bg2.jpg";
import DRSlogo from "../images/CertifiedDRSAgent-removebg-preview.png";

const PhysicianTab = () => (
  <section className="component bg-dirty-white">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6 col-lg-5 my-4">
          <div
            class="card text-center d-flex flex-column h-full"
            style={{
              backgroundImage: `url(${physicianBG})`,
              backgroundColor: "#012d6f",
              backgroundPosition: "bottom",
              backgroundSize: "cover",
              padding: "4rem 3rem",
            }}
          >
            <img
              class="card-img-top mt-0 mb-3"
              src={physicianlogo}
              alt="Card image cap"
              style={{
                objectFit: "contain",
                width: "80px",
                height: "80px",
                margin: "auto",
                filter: "brightness(0) invert(1)",
              }}
            />

            <h5 class="card-title text-uppercase pb-3 text-white">
              Physicians &amp; Medical Professionals
            </h5>
            <div className="card-divider" />
            <p class="card-text pt-2 text-white-body">
              Imagine real estate simplified! When you choose The Brian Boals
              Team to help you buy or sell, you're choosing expertise,
              integrity, and commitment.
            </p>
            <div className="d-flex justify-content-center mt-auto pt-5">
              <Link
                href="/physicians-medical"
                className="alink-white hvr-shadow"
                role="button"
              >
                Learn More
              </Link>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-5 my-4">
          <div
            class="card text-center d-flex flex-column h-full"
            style={{
              backgroundImage: `url(${medstudentBG})`,
              backgroundColor: "#012d6f",
              backgroundPosition: "bottom",
              backgroundSize: "cover",
              padding: "4rem 3rem",
            }}
          >
            <img
              class="card-img-top mt-0 mb-3"
              src={medicinelogo}
              alt="Card image cap"
              style={{
                objectFit: "contain",
                width: "80px",
                height: "80px",
                margin: "auto",
                filter: "brightness(0) invert(1)",
              }}
            />

            <h5 class="card-title text-uppercase pb-3 text-white">
              Medical Students <br /> &amp; Residents
            </h5>
            <div className="card-divider" />
            <p class="card-text pt-2 text-white-body">
              Buying a home is an important decision. Let us help you stay
              updated with the market so that when you are ready for home
              ownership, you reap all of its benefits.
            </p>
            <div className="d-flex justify-content-center mt-auto pt-5">
              <Link
                href="/medical-students-residents"
                className="alink-white hvr-shadow"
                role="button"
              >
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center mt-5">
        <img src={DRSlogo} width={200} className="img-fluid" alt="DRS Logo" />
        <h2 className="mt-3 h4 font-weight-normal text-center">
          Proud to be DRS Certified.
        </h2>
      </div>
    </div>
  </section>
);

export default PhysicianTab;

import React from 'react'
import first from '../../images/first-buyer.jpg'
import { Link } from 'gatsby'

const FirstBuyers = () => (
  <section
    className="firstBuyers-section"
    // style={{
    //   backgroundImage: `url(${physicianBG})`,
    //   backgroundColor: '#012d6f',
    //   backgroundPosition: 'center',
    //   backgroundSize: 'cover',
    //   backgroundRepeat: 'no-repeat',
    // }}
  >
    <div className="container px-5 mx-auto">
      <div className="row align-items-center box-shadow">
        <div className="col-12 col-lg-6 mb-5 mb-lg-0">
          <div className="left-info">
            <h2 className="f-poppins mb-4">First-Time Home Buyers</h2>
            <p className="mb-4">
              Is this your first time to buy a home in Colorado Springs? Use
              this financing guide from a Colorado Springs real estate agent to
              help you with the process.
            </p>
            <div className="position-relative mb-3">
              <Link
                to="/contact/"
                className="alink hvr-shadow my-1 text-center primary-button btn-before border-none"
              >
                Book a Free Consultation
              </Link>
            </div>
            <Link
              to="/buying-process/"
              className="alink hvr-shadow text-center secondary-button btn-before"
              role="button"
            >
              Process Guide
            </Link>
          </div>
        </div>
        <div className="col-12 col-lg-6 h-100">
          <div className="bg-image">
            <div className="back-bg" />
            <div
              className="img"
              style={{
                backgroundImage: `url(${first})`,
                backgroundColor: '#012d6f',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default FirstBuyers

import React from 'react'
import first from '../../images/physician-img.jpg'
import { Link } from 'gatsby'

const PhysicianRelocation = () => (
  <section
    className="relocation-section"
    // style={{
    //   backgroundImage: `url(${physicianBG})`,
    //   backgroundColor: '#012d6f',
    //   backgroundPosition: 'center',
    //   backgroundSize: 'cover',
    //   backgroundRepeat: 'no-repeat',
    // }}
  >
    <div className="container px-5 mx-auto">
      <div className="row align-items-center box-shadow">
        <div className="col-12 col-lg-6 h-100 mb-5 mb-lg-0">
          <div className="bg-image">
            <div className="back-bg" />
            <div
              className="img"
              style={{
                backgroundImage: `url(${first})`,
                backgroundColor: '#012d6f',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}
            />
          </div>
        </div>
        <div className="col-12 col-lg-6 pl-xl-5">
          <div className="left-info">
            <h2 className="f-poppins mb-4">Physician Relocation</h2>
            <p className="mb-4">
              When you choose The Brian Boals Team to help you buy or sell,
              you're choosing expertise, integrity, and commitment.
            </p>
            <div className="position-relative mb-3">
              <Link
                to="/physicians-medical/"
                className="alink hvr-shadow my-1 text-center primary-button btn-before border-none"
              >
                Physicians & Medical Professionals
              </Link>
            </div>
            <Link
              to="/medical-students-residents/"
              className="alink hvr-shadow text-center secondary-button btn-before"
              role="button"
            >
              Medical Students & Residents
            </Link>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default PhysicianRelocation

import React from 'react'
import WebriQForm from '@webriq/gatsby-webriq-form'
import sectionBG from '../../images/blue-bg1.jpg'
import sectionBG2 from '../../images/bg-red1.jpg'
import { LazyLoadImage } from 'react-lazy-load-image-component'

class ContactUs extends React.Component {
  render() {
    return (
      <section
        id="contact"
        className="component"
        style={{
          backgroundImage: `url(${sectionBG})`,
          backgroundSize: 'cover',
          padding: '100px 0',
        }}
      >
        <div className="container">
          <div className="row justify-content-center no-gutters">
            <div className="col-sm-10 col-lg-7">
              <div className="bg-white p-5 h-100 contact-section-col1">
                <div className="section-heading mb-5">
                  <h2>Request More Information</h2>
                </div>
                <WebriQForm data-form-id="5c8748656d01764f7a54174e">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group ">
                        <label htmlFor="inputFirstName">First Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="Fist Name"
                          id="inputFirstName"
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="inputLastName">Last Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="Last Name"
                          id="inputLastName"
                          required={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="inputEmail">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          name="Email"
                          id="inputEmail"
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="inputPhone">Phone (optional)</label>
                        <input
                          type="tel"
                          className="form-control"
                          id="inputPhone"
                          name="Phone"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputSubject">Subject (optional)</label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputSubject"
                      name="Subject"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputMessage">Message</label>
                    <textarea
                      className="form-control"
                      rows="4"
                      name="Message"
                      id="inputMessage"
                    />
                  </div>
                  <div className="text-left">
                    <div className="webriq-recaptcha mb-4" />
                    <button className="alink hvr-shadow" type="submit">
                      Send Message
                    </button>
                  </div>
                </WebriQForm>
              </div>
            </div>
            <div className="col-sm-10 col-lg-4 ">
              <div
                className="p-5 h-100 contact-section-col2"
                style={{
                  background: 'rgba(39, 39, 39, 0.7)',
                  backgroundImage: `url(${sectionBG2})`,
                }}
              >
                <h3 className="mt-2 mb-5 text-white">Contact Details</h3>
                <ul
                  className={`list-unstyled text-dirty-white mt-4 contact-info`}
                >
                  <li>
                    <LazyLoadImage
                      effect="blur"
                      alt=""
                      src="/img/001-facebook-placeholder-for-locate-places-on-maps.svg"
                      width="18"
                    />
                    <span>
                      12265 Oracle Boulevard, Suite 200 - Colorado Springs, CO
                      80921
                    </span>
                  </li>
                  <li>
                    <LazyLoadImage
                      effect="blur"
                      alt=""
                      src="/img/send.svg"
                      width="18"
                    />
                    <a
                      href="mailto:brianboals@gmail.com"
                      className=""
                      target="_blank"
                      rel="noopener noreferrer"
                      title="email"
                    >
                      brianboals@gmail.com
                    </a>
                  </li>
                  <li>
                    <LazyLoadImage
                      effect="blur"
                      alt=""
                      src="/img/004-vintage-telephone-call.svg"
                      width="18"
                    />
                    <a
                      href="tel:719-459-9955"
                      className=""
                      target="_blank"
                      rel="noopener noreferrer"
                      title="telephone"
                    >
                      (719) 459-9955
                    </a>
                  </li>
                  {/* <li>
                    <LazyLoadImage
                      effect="blur"
                      alt=""
                      src="/img/003-mobile-phone.svg"
                      width="18"
                    />
                    <a
                      href="tel:719-534-7922"
                      className=""
                      target="_blank"
                      rel="noopener noreferrer"
                      title="mobile phone"
                    >
                      (719) 534-7922
                    </a>
                  </li> */}
                  {/* <li>
                    <LazyLoadImage
                      effect="blur"
                      alt=""
                      src="/img/002-telephone.svg"
                      width="18"
                    />
                    <a
                      href="tel:719-594-4573"
                      className=""
                      target="_blank"
                      rel="noopener noreferrer"
                      title="fax"
                    >
                      (719) 594-4573
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default ContactUs

import React from 'react'
import physicianBG from '../../images/blue-outline-bg.jpg'

const BoldPrediction = () => (
  <section
    className="predictions-section"
    style={{
      backgroundImage: `url(${physicianBG})`,
      backgroundColor: '#012d6f',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    }}
  >
    <div className="container">
      <div className="row align-items-center">
        <div className="col-12 col-md-6 text-center text-md-left">
          <h4 className="text-white f-poppins">
            Learn About Bold Predictions for 2024
          </h4>
        </div>
        <div className="col-12 col-md-6">
          <div className="d-flex justify-content-center justify-content-md-end">
            <a
              href="https://www.buffiniandcompany.com/products/bold-predictions/bold-predictions-2024.aspx"
              className="alink hvr-shadow mx-md-2 my-1 text-center secondary-button btn-before"
              role="button"
              target="_blank"
            >
              Bold Predictions 2024
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default BoldPrediction

import React from 'react'
import first from '../../images/military-img.jpg'
import { Link } from 'gatsby'

const MilitaryRelocation = () => (
  <section
    className="firstBuyers-section"
    // style={{
    //   backgroundImage: `url(${physicianBG})`,
    //   backgroundColor: '#012d6f',
    //   backgroundPosition: 'center',
    //   backgroundSize: 'cover',
    //   backgroundRepeat: 'no-repeat',
    // }}
  >
    <div className="container px-5 mx-auto">
      <div className="row align-items-center box-shadow">
        <div className="col-12 col-lg-6 mb-5 mb-lg-0">
          <div className="left-info">
            <h2 className="f-poppins mb-4">Military Relocation</h2>
            <p className="mb-4">
              Finding the right home can be a difficult process but no matter
              what you are looking for as an experienced Colorado Springs, CO
              real estate agent I can provide you with the resources you need to
              make the home buying process simpler.
            </p>
            <div className="position-relative mb-3">
              <Link
                to="/military/"
                className="alink hvr-shadow my-1 text-center primary-button btn-before border-none"
              >
                Military RELOCATION
              </Link>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 h-100">
          <div className="bg-image">
            <div className="back-bg" />
            <div
              className="img"
              style={{
                backgroundImage: `url(${first})`,
                backgroundColor: '#012d6f',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default MilitaryRelocation

import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const Remax = () => (
  <section id="remax" className="component bg-dirty-white py-5">
    <div className="container">
      <div className="row align-items-center justify-content-center">
        <div className="col-md-3">
          <LazyLoadImage
            effect="blur"
            className="img-fluid mb-0"
            src="/img/remax-logo.png"
            alt="remax"
            width="300"
          />
        </div>
        <div className="col-md-9 col-lg-7 col-xl-6">
          <h4 className="font-weight-normal">
            Brian Boals Team at RE/MAX Real Estate Group.
          </h4>
          <p className="mb-0">
            215 W Rockrimmon Blvd Colorado Springs, CO 80919
          </p>
        </div>
      </div>
    </div>
  </section>
)

export default Remax
